import {RiSearchLine} from 'react-icons/ri'
import {useState, useEffect} from 'react'
import {Input, InputGroup, InputGroupText} from 'reactstrap'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import axiosInstance from '../../config/axios/axiosConfig'
import {useDispatch, useSelector} from 'react-redux'
import {fetchSearchHistory} from '../../store/reducers/searchReducer'
import {AppDispatch, RootState} from '../../store/store'
import {toast} from 'react-toastify'
import {AxiosError} from 'axios'
import { useAppSelector } from '../../store/hooks/reduxHooks'

type ConvertationType = {
  question: string
  answer: any
  suggestions: string[]
  references: any[]
}
function Search() {
  const clientIp = useAppSelector((state) => state.auth.clientIp)
  const dispatch = useDispatch<AppDispatch>()
  const conversations = useSelector((state: RootState) => state.search.conversations)
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated)
  const typingSpeed = 250
  const responseSpeed = 40
  const placeholderText = 'Posez question ici à notre IA et elle vous répondra'
  const [animatedText, setAnimatedText] = useState('')
  const [feedback, setFeedback] = useState<{query_id: string; query_text: string}>({
    query_id: '',
    query_text: '',
  })
  const [selectedCountry, setSelectedCounytry] = useState('TN')
  const [searchAlreadyClicked, setSearchAlredyClicked] = useState(false)
  const [textResponse, setTextResponse] = useState('')
  const [answerIsLoading, setAnswerIsLoading] = useState(false)
  const [suggestionIsLoading, setSuggestionIsLoading] = useState(false)
  const [referenceIsLoading, setReferenceIsLoading] = useState(false)

  const [animatedTextResponse, setAnimatedTextResponse] = useState('')
  const [question, setQuestion] = useState('')
  const [selectedDomain, setSelectedDomain] = useState('legal')
  const [showedReferences, setShowedReferences] = useState<number[]>([])
  const [suggestionPromiseResult, setSuggestionPromiseResult] = useState<any[]>([])
  const [referencesPromiseResult, setReferencesPromiseResult] = useState<any[]>([])
  const [answerPromiseResult, setAnswerPromiseResult] = useState<any[]>([])
  const [questions, setQuestions] = useState<any[]>([])
  const suggestion = [
    'Indemnité dite de risque militaire',
    'Fondation fidaa structure',
    'Quelle est la structure de fondation fidaa',
    'Conflit de compétence soulevé entre les tribunaux militaires et les tribunaux judiciaires',
    'المخالفات عاديّة من الصنف الخامس على معنى الفصل 83 من مجلة الطرقات',
    'مراقب المالية من الدرجة الأولى المقدار الشهري',
  ]

  const handlefeedback = () => {
    const data = axiosInstance.post('/search/api/v1/feedback/', {
      feedback: true,
      query_id: feedback.query_id,
      query_text: feedback.query_text,
    })
    setFeedback({
      query_id: '',
      query_text: '',
    })
  }
  const [selectedReference, setSelectedReference]: any = useState()
  function handleTextareaKeyDown(event: {target: any}) {
    // const textarea = event.target
    // textarea.style.height = 'auto'
    // textarea.style.height = `${textarea.scrollHeight}px`
  }
  const handleShowMoreReferences = (conversationIndex: number) => {
    setShowedReferences((previousValues: any) => [...previousValues, conversationIndex])
  }
  const handleShowLessReferences = (conversationIndex: number) => {
    setShowedReferences((previousValue: any) =>
      previousValue.filter((item: any) => item !== conversationIndex)
    )
  }
  useEffect(() => {
    let currentIndex = 0
    const interval = setInterval(() => {
      currentIndex = (currentIndex + 1) % (placeholderText.length + 1)
      setAnimatedText(placeholderText.slice(0, currentIndex))
    }, typingSpeed)
    return () => clearInterval(interval)
  }, [placeholderText, typingSpeed])

  useEffect(() => {
    let currentIndex = 0
    const interval = setInterval(() => {
      if (currentIndex < textResponse.length) {
        currentIndex++
        setAnimatedTextResponse(textResponse.slice(0, currentIndex))
      } else {
        clearInterval(interval) // Stop the animation when currentIndex reaches the end.
      }
    }, responseSpeed)
    return () => clearInterval(interval)
  }, [textResponse])

  useEffect(() => {
    if (conversations.length > 0) {
      setQuestions([conversations[conversations.length - 1].question])
      setQuestion(conversations[conversations.length - 1].question)
      setAnswerPromiseResult([conversations[conversations.length - 1].results[0].resp])
      setReferencesPromiseResult([conversations[conversations.length - 1].results[1].resp])
      setSuggestionPromiseResult([conversations[conversations.length - 1].results[2].resp])
      
    }
  }, [JSON.stringify(conversations)])
  
  const handleSearch = async () => {
    const searchInput = document.getElementById('search-input')! as HTMLInputElement
    const searchInputValue = searchInput.value
    if (searchInputValue === '') return
    setAnswerIsLoading(true)
    setSuggestionIsLoading(true)
    setReferenceIsLoading(true)
    searchInput.value = ''
    setQuestion(searchInputValue)
    setQuestions((prev: any) => [...prev, searchInputValue])
    axiosInstance
      .post(`/search/api/v1/answer/`, {
        question: searchInputValue,
        query_type: selectedDomain,
        language: 'French',
        client_ip: clientIp
      })
      .then((answerPromise: any) => {
        setAnswerPromiseResult((prev: any) => [...prev, answerPromise.data])
        if (answerPromise.data) setAnswerIsLoading(false)
      })
      .catch((error: AxiosError) => {
        console.log(error);
        
        if (error) {
          toast.error('Désolé, le nombre de réponses générées par jour est limité !')
          setAnswerIsLoading(false)
          setAnswerPromiseResult((prev: any) => [...prev])
        }
      })

    const referencesPromise = await axiosInstance.post(
      `/search/api/v1/search?question=${searchInputValue}&ask_type=legal`
    )
    setReferencesPromiseResult((prev: any) => [...prev, referencesPromise.data])
    if (referencesPromise.data) setReferenceIsLoading(false)
    const suggestionPromise = await axiosInstance.post(`/search/api/v1/suggest/`, {
      query: searchInputValue,
      language: 'French',
    })
    setSuggestionPromiseResult((prev: any) => [...prev, suggestionPromise.data])
    if (suggestionPromise.data) setSuggestionIsLoading(false)

    if (!searchAlreadyClicked) {
      document.getElementById('search-accordion-header')?.click()
      setSearchAlredyClicked(true)
    }
    setTimeout(() => {
      if (isAuthenticated) dispatch(fetchSearchHistory())
    }, 2000)
  }
  const trimedText = (text: string) => text?.substring(text.indexOf(':') + 1).trim()
  return (
    <div className='w-100'>
      <div className='d-flex flex-column justify-content-center align-items-center flex-wrap gap-5'>
        <div className='h1 fw-semibold  py-5'>Legaly AI Search</div>
        <div className='row w-lg-50  pt-5 gap-5'>
          <div className='col'>
            <label className='fs-2' htmlFor=''>
              Pays :
            </label>
          </div>
          <div className='col'>
            <label
              htmlFor='tn-country'
              className='form-check form-check-custom form-check-solid cursor-pointer'
            >
              <span className='accordion-icon'>
                <KTSVG className='svg-icon svg-icon-2x' path='/media/flags/tunisia.svg' />
              </span>
              <h3 className='fs-4 text-gray-800 fw-bold mb-0 mx-4'>TN</h3>
              <input
                id='tn-country'
                className='form-check-input '
                type='radio'
                value={'TN'}
                name='country'
                checked={selectedCountry === 'TN'}
                onChange={() => setSelectedCounytry('TN')}
              />
            </label>
          </div>
          <div className='col '>
            <label
              htmlFor='fr-country'
              className='form-check form-check-custom form-check-solid cursor-pointer'
            >
              <span className='accordion-icon'>
                <KTSVG className='svg-icon svg-icon-2x' path='/media/flags/france.svg' />
              </span>
              <h3 className='fs-4 text-gray-800 fw-bold mb-0 mx-4'>FR</h3>
              <input
                id='fr-country'
                className='form-check-input'
                type='radio'
                value={'FR'}
                disabled
                name='country'
                checked={selectedCountry === 'FR'}
                onChange={() => setSelectedCounytry('FR')}
              />
            </label>
          </div>
        </div>
        <div className='row w-lg-50 '>
          <div className='col'>
            <label className='fs-2 ' htmlFor=''>
              Type :{' '}
            </label>
          </div>
          <div className='col '>
            <label
              htmlFor='law-domain'
              className='form-check form-check-custom form-check-solid cursor-pointer'
            >
              <span className='accordion-icon'>
                <img
                  alt=''
                  className='svg-icon svg-icon-2x'
                  src={toAbsoluteUrl('/media/law.png')}
                  width={35}
                  height={35}
                />
              </span>
              <h3 className='fs-4 text-gray-800 fw-bold mb-0 mx-4'>Juridique</h3>
              <input
                id='law-domain'
                className='form-check-input'
                type='radio'
                value={'legal'}
                name='domain'
                checked={selectedDomain === 'legal'}
                onChange={() => setSelectedDomain('legal')}
              />
            </label>
          </div>
          <div className='col'>
            <label
              htmlFor='general-domain'
              className='form-check form-check-custom form-check-solid cursor-pointer'
            >
              <span className='accordion-icon'>
                <img
                  alt=''
                  className='svg-icon svg-icon-2x'
                  src={toAbsoluteUrl('/media/paper.png')}
                  width={35}
                  height={35}
                />
              </span>
              <h3 className='fs-4 text-gray-800 fw-bold mb-0 mx-4'>Général</h3>
              <input
                id='general-domain'
                className='form-check-input'
                type='radio'
                value={'general'}
                name='domain'
                checked={selectedDomain === 'general'}
                onChange={() => setSelectedDomain('general')}
              />
            </label>
          </div>
        </div>
        <div className='w-lg-75'>
          <div className=' accordion accordion-icon-toggle show' id='kt_accordion_2'>
            <div className='mb-5 w-100 '>
              <div
                className='accordion-header py-3 d-flex'
                data-bs-toggle='collapse'
                data-bs-target='#kt_accordion_2_item_1'
                id='search-accordion-header'
              >
                <span className='accordion-icon'>
                  <KTSVG
                    className='svg-icon svg-icon-4'
                    path='/media/icons/duotune/arrows/arr064.svg'
                  />
                </span>
                <h3 className='fs-4 text-gray-800 fw-bold mb-0 ms-4'>
                  Découvrir les sujets populaires
                </h3>
              </div>
              <div
                id='kt_accordion_2_item_1'
                className='fs-6 collapse show ps-10'
                data-bs-parent='#kt_accordion_2'
              >
                <div>
                  {suggestion.map((s, i) => (
                    <p dir='auto'>
                      <div
                        className='p-2 rounded-3 bg-secondary cursor-pointer'
                        onClick={() => {
                          ;(document.getElementById('search-input')! as HTMLInputElement).value = s
                          handleSearch()
                        }}
                      >
                        {s}
                      </div>
                    </p>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='d-flex flex-column gap-3 w-100'>
          <div className=' bg-body rounded'>
            <InputGroup>
              <Input
                rows={1}
                type='text'
                placeholder={animatedText}
                onKeyDown={(event) =>
                  event.key === 'Enter' ? handleSearch() : handleTextareaKeyDown
                }
                id='search-input'
              />
              <span className='w-110px'>
                <Input id='exampleSelect' name='select' type='select' className=''>
                  <option>Française</option>
                  <option dir='auto'>عربي</option>
                </Input>
              </span>
              <InputGroupText className='cursor-pointer' onClick={handleSearch}>
                <RiSearchLine />
              </InputGroupText>
            </InputGroup>
          </div>
          {/* <div  className='d-flex justify-content-evenly gap-3 flex-wrap w-100 m-2'>
            <FormGroup switch>
              <Input type='switch' role='switch' />
              <Label check> Tunis</Label>
            </FormGroup>
            <div>|</div>
            <FormGroup switch>
              <Input type='switch' role='switch' />
              <Label check> France</Label>
            </FormGroup>
          </div> */}
        </div>
        {questions?.map((question: string, convertationIndex: number) => (
          <div className=' card w-100'>
            <div className='row'>
              <div className='col-lg-8'>
                <div className='card h-100 card-xl-stretch mb-5 mb-xl-8 '>
                  <div className='card-header'>
                    <div className='w-100 card-title'>
                      <div className='w-100 h-100 d-flex justify-content-between align-items-center'>
                        <div>
                          <label htmlFor=''>Objet: {question}</label>
                        </div>
                        <div className='d-flex  align-items-center'>
                          <div
                            className='px-2 pt-2'
                            data-bs-toggle='modal'
                            data-bs-target='#feedback_modal_content'
                            onClick={() => {
                              setFeedback((feedback) => ({
                                ...feedback,
                                query_id: answerPromiseResult[convertationIndex]?.deep_code,
                              }))
                            }}
                          >
                            <span className='accordion-icon'>
                              <i className='bi bi-hand-thumbs-up-fill fs-2x cursor-pointer'></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='card-body'>
                    {answerIsLoading && convertationIndex === questions.length - 1 ? (
                      <div>
                        <img
                          alt=''
                          className='svg-icon svg-icon-2x'
                          src={toAbsoluteUrl('/media/loader.gif')}
                          width={200}
                          height={200}
                        />
                      </div>
                    ) : answerPromiseResult[convertationIndex]?.answer[0]?.text ? (
                      answerPromiseResult[convertationIndex]?.answer[0]?.text
                    ) : (
                      'Désolé, le nombre de réponses générées par jour est limité !'
                    )}
                  </div>

                  <div className='card-footer'>
                    <div className='w-lg-75'>
                      <div className='accordion accordion-icon-toggle ' id='kt_accordion_2-related'>
                        <div className='mb-5 w-100 '>
                          <div
                            className='accordion-header py-3 d-flex'
                            data-bs-toggle='collapse'
                            data-bs-target='#kt_accordion_2_item_1-related'
                            id='search-accordion-header-related'
                          >
                            <span className='accordion-icon'>
                              <KTSVG
                                className='svg-icon svg-icon-4'
                                path='/media/icons/duotune/arrows/arr064.svg'
                              />
                            </span>
                            <h3 className='fs-7 text-gray-800 fw-bold mb-0 ms-4'>
                              Questions liée:
                            </h3>
                          </div>
                          <div
                            id='kt_accordion_2_item_1-related'
                            className='fs-6 collapse show  ps-10'
                            data-bs-parent='#kt_accordion_2-related'
                          >
                            {suggestionIsLoading && convertationIndex === questions.length - 1 ? (
                              <div>
                                <img
                                  alt=''
                                  className='svg-icon svg-icon-2x'
                                  src={toAbsoluteUrl('/media/loader.gif')}
                                  width={200}
                                  height={200}
                                />
                              </div>
                            ) : (
                              <div>
                                {suggestionPromiseResult[convertationIndex]?.suggestions?.map(
                                  (s: any, i: number) => (
                                    <p dir='auto'>
                                      <div
                                        className='p-2 rounded-3 fs-7 bg-secondary cursor-pointer'
                                        onClick={() => {
                                          ;(
                                            document.getElementById(
                                              'search-input'
                                            )! as HTMLInputElement
                                          ).value = s
                                          handleSearch()
                                        }}
                                      >
                                        {s}
                                      </div>
                                    </p>
                                  )
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4'>
                <div className='card p-3'>
                  <div className='card-header'>
                    <div className='card-title'>Références</div>
                  </div>
                  {referenceIsLoading && convertationIndex === questions.length - 1 ? (
                    <div>
                      <img
                        alt=''
                        className='svg-icon svg-icon-2x'
                        src={toAbsoluteUrl('/media/loader.gif')}
                        width={200}
                        height={200}
                      />
                    </div>
                  ) : (
                    <div className='card-body'>
                      {referencesPromiseResult[convertationIndex]?.documents
                        ?.slice(
                          0,
                          showedReferences.includes(convertationIndex)
                            ? referencesPromiseResult[convertationIndex]?.documents.length
                            : 3
                        )
                        .map((reference: any, i: number) => (
                          <div
                            key={i}
                            onClick={() => setSelectedReference(reference)}
                            data-bs-toggle='modal'
                            data-bs-target='#reference_content'
                          >
                            <div className='d-flex gap-2 cursor-pointer'>
                              <span className='btn btn-icon  flex-center bg-light btn-color-primary btn-active-color-primary h-40px'>
                                <KTSVG
                                  path='/media/icons/duotune/coding/cod002.svg'
                                  className='svg-icon svg-icon-3x'
                                />
                              </span>
                              <p className='p-2 rounded-3 bg-secondary' dir='auto'>
                                {reference?.metadata?.doc_title}
                              </p>
                            </div>
                          </div>
                        ))}
                      {showedReferences.includes(convertationIndex) ? (
                        <button
                          type='button'
                          onClick={() => handleShowLessReferences(convertationIndex)}
                          className='btn btn-link'
                        >
                          Voir moins
                        </button>
                      ) : (
                        <button
                          type='button'
                          onClick={() => handleShowMoreReferences(convertationIndex)}
                          className='btn btn-link'
                        >
                          Voir plus
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
        {/* {reponseIsLoading && (
          <div>
            <img
              alt=''
              className='svg-icon svg-icon-2x'
              src={toAbsoluteUrl('/media/loader.gif')}
              width={200}
              height={200}
            />
          </div>
        )} */}
      </div>

      <div className='modal fade' tabIndex={-1} id='reference_content'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>
                {`${trimedText(selectedReference?.metadata?.meta_infos)} n°${trimedText(
                  selectedReference?.metadata?.meta_infos_2
                )} du ${trimedText(selectedReference?.metadata?.meta_infos_3)}   
                
                `}
              </h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body' style={{overflowY: 'auto'}}>
              <p>{selectedReference?.page_content}</p>
            </div>
          </div>
        </div>
      </div>
      <div className='modal fade' tabIndex={-1} id='feedback_modal_content'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Feedback</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body' style={{overflowY: 'auto'}}>
              <div className='d-flex flex-column flex-between gap-3'>
                <textarea
                  className='form-control'
                  name=''
                  id=''
                  cols={30}
                  rows={10}
                  onChange={(e) =>
                    setFeedback((feedback) => ({...feedback, query_text: e.target.value}))
                  }
                />
                <button
                  className='btn btn-primary'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                  onClick={handlefeedback}
                >
                  Envoyer
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Search
