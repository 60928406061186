import {toAbsoluteUrl} from '../../../../helpers'

/* eslint-disable jsx-a11y/anchor-is-valid */
const SubscriptionsTab = () => (
  <></>
  // <div className='mx-5'>
  //    <div className='text-center pt-10 mb-20'>
  //     <h2 className='fs-2 fw-bolder mb-7'>My Subscription</h2>

  //     <p className='text-gray-400 fs-4 fw-bold mb-10'>
  //       There are no customers added yet.
  //       <br />
  //       Kickstart your CRM by adding a your first customer
  //     </p>

  //     <a href='#' className='btn btn-primary'>
  //       Upgrade Plan
  //     </a>
  //   </div>

  //   <div className='text-center px-4'>
  //     <img
  //       src={toAbsoluteUrl('/media/illustrations/sigma-1/18.png')}
  //       alt=''
  //       className='mw-100 mh-300px'
  //     />
  //   </div>
  // </div>
)

export {SubscriptionsTab}
