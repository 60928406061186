import {useForm} from 'react-hook-form'
import {useEffect, useState} from 'react'
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import useTextAnimation from '../../utils/useTextAnimation'
import {toAbsoluteUrl} from '../../../_metronic/helpers/AssetHelpers'
import axiosInstance from '../../config/axios/axiosConfig'
import Select from 'react-select'
import {useThemeMode} from '../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import {AppDispatch} from '../../store/store'
import {useAppDispatch, useAppSelector} from '../../store/hooks/reduxHooks'
import {Document, Page, Text, View, StyleSheet, PDFViewer} from '@react-pdf/renderer'

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
  },
  section: {
    flexGrow: 1,
    padding: 10,
  },
})

function Paper() {
  const [generateIsLoading, setGenerateIsLoading] = useState(false)
  const paperList: any[] = useAppSelector((state) => state.paper.papersList)
  const {register, handleSubmit} = useForm()
  const [selectedPaper, setSelectedPaper] = useState<any>()
  const {mode, menuMode, updateMode, updateMenuMode} = useThemeMode()
  const textResponse = '' // Replace this with your desired text
  const responseSpeed = 150
  const animatedPlaceholder = useTextAnimation(textResponse, responseSpeed)
  const [openPdfModal, setOpenPdfModal] = useState(false)
  const [improvmentReq, setImprovmentReq] = useState(false)
  const [responseDoc, setResponseDoc] = useState<boolean>(false)
  const [responseDocText, setResponseDocText] = useState<string>('')
  const improveFile = () => {
    setImprovmentReq(true)
  }
  const paperFormSubmit = async () => {
    const brief = document.getElementById('responseDocTextareaId') as HTMLTextAreaElement
    setGenerateIsLoading(true)
    const {data} = await axiosInstance.post('/search/api/v1/paper/', {
      code: selectedPaper!.id,
      names: brief.value,
      language: 'French',
    })

    brief.value = data.documents
    setGenerateIsLoading(false)
    setResponseDoc(true)
    setResponseDocText(data.documents)
  }

  const toggle = () => {
    setImprovmentReq(false)
    setOpenPdfModal(!openPdfModal)
  }
  const handleAccepterClick = () => {
    const pdfUrl = toAbsoluteUrl('/media/location_habitation.pdf')
    const downloadLink = document.createElement('a')
    downloadLink.href = pdfUrl
    downloadLink.download = 'location_habitation.pdf'
    downloadLink.click()
    toggle() 
  }
  useEffect(() => {
    console.log(selectedPaper)
  }, [selectedPaper])
  useEffect(() => {
    setSelectedPaper(paperList[0])
  }, [paperList.length])
  return (
    <>
      <>
        {' '}
        <div className='d-flex flex-column flex-column-fluid justify-content-center align-items-center'>
          <div className='h1 fw-semibold  py-5'>Legaly AI Paper Draft</div>
          <div className='fs-4 text-center'>
            laborez des ébauches de documents juridiques de façon intuitive grâce à notre
            technologie d’IA. Notre plateforme vous accompagne à chaque étape du processus de
            rédaction, rendant la conception d’ébauches juridiques un véritable jeu d’enfant. Avec
            Legaly.tn, la complexité de la rédaction juridique est transformée en simplicité.
          </div>
        </div>
        <div className='row mt-5 '>
          <div className='col-lg-3 p-5'>
            Pays
            <Select
              options={[
                {value: '1', label: ' 🇹🇳 Tunisia (Beta)'},
                {value: '2', label: ' 🇲🇫 France (Beta)'},
              ]}
              defaultValue={{value: '1', label: ' 🇹🇳 Tunisia (Beta)'}}
              isSearchable
              className='react-select-container'
              classNamePrefix='react-select'
            />
          </div>
          <div className='col-lg-3 p-5'>
            Langue
            <Select
              options={[{value: '1', label: '  Français'}]}
              defaultValue={{value: '1', label: '  Français'}}
              isSearchable
              className='react-select-container'
              classNamePrefix='react-select'
            />
          </div>
          <div className='col-lg-6 p-5'>
            Type de document
            <Select
              options={paperList?.map((item: any) => {
                return {value: item.id, label: item.title}
              })}
              defaultValue={{value: paperList[0]?.id, label: paperList[0]?.title}}
              isSearchable
              className='react-select-container'
              classNamePrefix='react-select'
              onChange={(selectedOption) => {
                const selectedPaperId = selectedOption?.value
                const selectedPaper = paperList.find((paper) => paper.id === selectedPaperId)
                setSelectedPaper(selectedPaper)
              }}
            />
          </div>
          <div className='offset-2 col-8  offset-2 p-5'>
            <textarea
              placeholder={animatedPlaceholder}
              className='form-control'
              name=''
              id='responseDocTextareaId'
              cols={20}
              rows={20}
            ></textarea>
          </div>
          <div className='d-flex justify-content-center gap-lg-6 gap-2'>
            <button
              disabled={generateIsLoading}
              onClick={paperFormSubmit}
              type='button'
              className='btn btn-primary'
              data-kt-indicator={generateIsLoading ? 'on' : ''}
            >
              <span className='indicator-label'>Generate</span>
              <span className='indicator-progress'>
                S'il vous plaît, attendez...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            </button>
            {responseDoc && (
              <button
                onClick={() => setOpenPdfModal(!openPdfModal)}
                type='button'
                className='btn btn-primary'
              >
                Je valide
              </button>
            )}
          </div>
          <Modal fullscreen size='lg' isOpen={openPdfModal} toggle={toggle}>
            <ModalHeader toggle={toggle}>Contrat Location</ModalHeader>
            <ModalBody className='w-100'>
              <PDFViewer className='w-100 h-100'>
                <Document>
                  <Page size='A4'>
                    <View style={styles.section}>
                      <Text>{responseDocText}</Text>
                    </View>
                  </Page>
                </Document>
              </PDFViewer>
            </ModalBody>
            <ModalFooter>
              <Button color='secondary' onClick={toggle}>
                Retour
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </>
    </>
  )
}

export default Paper
