import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axiosInstance from '../../config/axios/axiosConfig'


export const fetchSearchHistory = createAsyncThunk(
  'search/fetchSearchHistory',
  async () => {
    const response = await axiosInstance.get('/search/api/historic/')
    return response.data
  }
)


export const searchSlice = createSlice({
  name: 'search',
  initialState: {
    conversations: [] as any[],
    histories: [],
  },
  reducers: {
    addConversation: (state, action) => {
      state.conversations = [action.payload]
    },
    clearConversations: (state) => {
      state.conversations = []
    }
   },
  extraReducers: (builder) => {
    builder.addCase(fetchSearchHistory.fulfilled, (state, action) => {
      state.histories = action.payload
    })
  },
})
export const {addConversation, clearConversations} = searchSlice.actions
export default searchSlice.reducer