import {Suspense, useEffect, useState} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'
import {useAppDispatch, useAppSelector} from './store/hooks/reduxHooks'
import axiosInstance from './config/axios/axiosConfig'
import {fetchPapersList} from './store/reducers/paperReducer'
import {ToastContainer} from 'react-toastify'
import {getClientIp} from './store/reducers/authReducer'
import ReactGA from "react-ga4";

ReactGA.initialize("G-CJF7W22928");
const App = () => {
  const dispatch = useAppDispatch()
  const token = useAppSelector((state) => state.auth.token)
  axiosInstance.interceptors.request.use((config: any) => {
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  })
  useEffect(() => {
    
    dispatch(fetchPapersList())
    dispatch(getClientIp())
  }, [])

  return (
    <I18nProvider>
      <ToastContainer />
      <LayoutProvider>
        <AuthInit>
          <Outlet />
          <MasterInit />
        </AuthInit>
      </LayoutProvider>
    </I18nProvider>
  )
}

export {App}
