import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axiosInstance from '../../config/axios/axiosConfig'


export const fetchPapersList = createAsyncThunk(
  'paper/fetchPapersList',
  async () => {
    const response = await axiosInstance.get('/search/api/v1/paper/list/')
    return response.data
  }
)

export const paperSlice = createSlice({
  name: 'paper',
  initialState: {
    papersList: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPapersList.fulfilled, (state, action) => {
      state.papersList = action.payload
    })
  },
})
export default paperSlice.reducer